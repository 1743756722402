var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{staticClass:"pond-st-statistics-tab"},[_c('layout-toolbar',[_c('er-date-picker',{attrs:{"type":"daterange","unlink-panels":"","size":"mini","format":_vm.upm__getFormatDateString,"value-format":_vm.dateValueFormat,"popper-class":"st-statistics-picker","start-placeholder":_vm.$t('Comm_Start'),"end-placeholder":_vm.$t('Comn_end'),"timeZoneString":_vm.getUserTimeZoneString,"availableInterval":_vm.availableInterval,"disableDateMethod":_vm.disableDateMethod,"clearable":false},on:{"change":_vm.handleDateValueChange},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_vm._v(" "),_c('div',{staticClass:"filler"}),_vm._v(" "),_c('el-select',{attrs:{"placeholder":_vm.$t('Comn_select')},on:{"change":function($event){return _vm.handleDeviceTypeChange('devices')}},model:{value:(_vm.checkboxValue),callback:function ($$v) {_vm.checkboxValue=$$v},expression:"checkboxValue"}},_vm._l((_vm.checkboxOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.$t(item.label),"value":item.value}})}),1),_vm._v(" "),_c('el-select',{attrs:{"clearable":"","placeholder":_vm.$t('Comn_select')},on:{"change":function($event){return _vm.handleDeviceTypeChange('dataToDisplay')}},model:{value:(_vm.dataToDisplay),callback:function ($$v) {_vm.dataToDisplay=$$v},expression:"dataToDisplay"}},_vm._l((_vm.dataToDisplayOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.$t(item.label),"value":item.value}})}),1),_vm._v(" "),_c('er-legend',{attrs:{"legendItems":_vm.getLegendItems},scopedSlots:_vm._u([{key:"legend-title",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(item.title)+"\n      ")]}}])}),_vm._v(" "),_c('er-input',{attrs:{"inputType":"search","size":"mini","suffix-icon":"el-icon-search","placeholder":_vm.$t('Comn_search')},on:{"change":_vm.handelSearchChange},model:{value:(_vm.searchST),callback:function ($$v) {_vm.searchST=$$v},expression:"searchST"}})],1),_vm._v(" "),_c('el-row',{staticClass:"pond-st-statistics-tab-body"},[_c('er-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('el-row',{attrs:{"type":"flex","justify":"center"}},[(_vm.tableUpdatedData.length > 0)?_c('el-col',[_c('er-data-tables',{key:_vm.$i18n.locale,ref:"table",attrs:{"columnValue":_vm.columnValueData,"uniqueId":"pond-st-statistics","tableData":_vm.tableUpdatedData,"actionColumn":false,"columns":_vm.columns,"elTableProps":_vm.tableProps,"filters":_vm.tableFilters,"sortMethodForColumns":_vm.sortMethodForColumns,"type":"white-header-table","searchOnTableData":_vm.searchST},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var row = ref.row;
return [_c('el-row',{attrs:{"type":"flex"}},[_c('el-tooltip',{attrs:{"content":_vm.getDeviceLastCommunicationColorString(
                      row.data.comm_status
                    ).title,"popperClass":"st-settings-table-el-tooltip__popper","effect":"light","placement":"top"}},[_c('i',{staticClass:"material-icons-round",style:(("color:" + (_vm.getDeviceLastCommunicationColorString(
                          row.data.comm_status
                        ).color)))},[_vm._v("signal_cellular_alt")])]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"5px"}},[_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":_vm.getDeviceNameUnits,"withoutHighlightStrings":_vm.getPreferredUnits.device_name === 'alias_name'
                        ? row.data.title
                        : row.data.code}})],1)],1)]}},{key:"datacell_header",fn:function(ref){
                        var header = ref.header;
return [_c('div',{staticClass:"header-row-cell"},[_vm._v(_vm._s(header.column.label))]),_vm._v(" "),_c('span',[_c('i',{staticClass:"el-icon-plus",on:{"click":function($event){return _vm.toggleHourlyColumns($event, header.column.property)}}})])]}},{key:"datacell",fn:function(ref){
                        var row = ref.row;
return [_c('div',{staticClass:"value-holder",style:(_vm.tableCellClass(row.data[row.path]))},[_c('span',[_vm._v("\n                  "+_vm._s(_vm.ftm__formatEmpty(
                      _vm.ftm__digitPrecision(
                        (
                          row.data[row.path] || {
                            response_quality: undefined
                          }
                        ).response_quality,
                        1
                      )
                    ))+"\n                ")]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataToDisplay === 'noisePercent'),expression:"dataToDisplay === 'noisePercent'"}]},[_vm._v("\n                  /\n                  "+_vm._s(_vm.ftm__formatEmpty(
                      _vm.ftm__digitPrecision(
                        (
                          row.data[row.path] || {
                            response_quality: undefined
                          }
                        ).noise_percentage,
                        1
                      )
                    ))+"\n                ")]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataToDisplay === 'dispensedFeed'),expression:"dataToDisplay === 'dispensedFeed'"}]},[_vm._v("\n                  /\n                  "+_vm._s(_vm.ftm__formatEmpty(
                      _vm.ftm__digitPrecision(
                        (
                          row.data[row.path] || {
                            response_quality: undefined
                          }
                        ).dispensed_feed,
                        1
                      )
                    ))+"\n                ")])])]}},{key:"datacell_hourly",fn:function(ref){
                    var row = ref.row;
return [_c('div',{staticClass:"value-holder",style:(_vm.tableCellClass(row.childColumnsData[row.path]))},[_c('span',[_vm._v("\n                  "+_vm._s(_vm.ftm__formatEmpty(
                      _vm.ftm__digitPrecision(
                        (
                          row.childColumnsData[row.path] || {
                            response_quality: undefined
                          }
                        ).response_quality,
                        1
                      )
                    ))+"\n                ")]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataToDisplay === 'dispensedFeed'),expression:"dataToDisplay === 'dispensedFeed'"}]},[_vm._v("\n                  /\n                  "+_vm._s(_vm.ftm__formatEmpty(
                      _vm.ftm__digitPrecision(
                        (
                          row.childColumnsData[row.path] || {
                            response_quality: undefined
                          }
                        ).dispensed_feed,
                        1
                      )
                    ))+"\n                ")])])]}},_vm._l((_vm.getHourlyColumns),function(hourColumn){return {key:("dataCell_" + hourColumn),fn:function(ref){
                    var row = ref.row;
return [_c('div',{key:hourColumn,staticClass:"value-holder",style:(_vm.tableCellClass(row.data[hourColumn]))})]}}})],null,true)})],1):[_c('er-empty',{staticClass:"ST-response"})]],2),_vm._v(" "),_c('el-row',{staticClass:"abbr-row"},[_c('h6',[_vm._v("\n            "+_vm._s(_vm.$t('St_response_note_zero'))+"\n          ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }