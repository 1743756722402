<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: abw.vue
Description: This file is the abw component useful for taking abw inputs from the user. It contains the table rows as inputs
-->
<template>
  <layout-pondlogs headerType="card" containerType="card" class="culture-abw">
    <template slot="title">{{ $t("ABW_abw") }}</template>
    <template slot="header">
      <layout-toolbar>
        <er-single-day-picker
          :value="abwDate"
          value-format="yyyy-MM-dd"
          :format="this.upm__getFormatDateString"
          @change-prev-day="prevDayClick"
          @change-next-day="nextDayClick"
          @change-day="handlePresentDateChange"
        ></er-single-day-picker>
        <el-col :span="4.3">
          <er-input
            inputType="search"
            size="mini"
            v-model="searchPond"
            :placeholder="$t('Ponds_select_pond_name')"
            suffix-icon="el-icon-search"
            @change="handelSearchInput"
          ></er-input>
        </el-col>
      </layout-toolbar>
    </template>
    <el-row
      slot="layout-pondlogs-scrollable-main"
      v-loading="loading"
      element-loading-background="white"
    >
      <Loader v-if="loading"></Loader>
      <el-row v-if="!loading || tableDataUpdated.length > 0">
        <er-data-tables
          v-show="!loading"
          :tableData="tableDataUpdated"
          :key="$i18n.locale"
          :columns="tableColumns"
          type="white-header-table"
          uniqueId="abw-table"
          :action-column="false"
          ref="abwtable"
          :el-table-props="tableProps"
          :selectColumn="true"
          :pagination-slot="true"
          :sortMethodForColumns="sortMethodForColumns"
          :searchOnTableData="searchPond"
        >
          <template v-slot:selected_header="{ $index }">
            <el-checkbox
              :key="$index"
              :value="checkAll"
              :indeterminate="isIndeterminate"
              @change="handleSelectAll"
            ></el-checkbox>
          </template>
          <template v-slot:selected="{ row }">
            <el-checkbox v-model="row.data.selected" @change="handleChangeABW(row.data, 'checkBox')"></el-checkbox>
          </template>
          <template v-slot:pond_name="{ row }">
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              keyName="pond_title"
              :withoutHighlightStrings="row.data.pond_title"
            />
          </template>

          <template v-slot:last_abw="{ row }">
            {{
              row.data.last_abw === 0
                ? "--"
                : getValuetoFixed(row.data.last_abw)
            }}
          </template>

          <template v-slot:last_abw_date="{ row }">
            {{
              row.data.last_abw_date === "--"
                ? "--"
                : getABWLastDate(row.data.last_abw_date)
            }}
          </template>
          <!-- implementing the samples popover -->
          <template v-slot:samples="{ row }">
            <samples-table2
              :isDisabled="row.data.harvested_abw"
              :popover-data="row.data"
              @calculated="handelCalculate"
              :hasUpdatePermission="hasUpdatePermissions"
              @cancelled="handleSampleTablePopverCancel"
            ></samples-table2>
          </template>
          <template v-slot:abw_g="{ row }">
            <el-input-number
              :controls="false"
              :precision="2"
              :min="0"
              class="input_abw"
              size="mini"
              v-model="row.data.abw"
              :disabled="row.data.harvested_abw"
              @blur="handleChangeABW(row.data)"
              @change="handleChangeInABW($event, row.data)"
            ></el-input-number>
          </template>
          <template v-slot:weight_gain="{ row }">
            {{ ftm__digitPrecision(row.data.weight_gain, 2) }}
            <!-- calcWeightGain(scope.row) -->
          </template>
          <!-- need to implement the survival percentage -->
          <template v-slot:survival_percentage="{ row }">
            <!-- <template v-if="row.data.harvested_abw"> --
              v-else </template> -->
            <el-input-number
              class="input_survival_per"
              :controls="false"
              :precision="0"
              size="mini"
              :min="0"
              :disabled="row.data.harvested_abw"
              @blur="handleChangeABW(row.data, 'survival')"
              v-model="row.data.survival_percentage"
            ></el-input-number>
          </template>
          <template v-slot:days_of_culture="{ row }">
            {{ ftm__formatEmpty(getDOC(row.data.cultivation_date)) }}
          </template>
          <el-row type="flex" slot="pagination" class="btns-container">
            <er-button
              btnType="save"
              :showIcon="true"
              :disabled="isReadOnly"
              :loading="loading"
              @click="saveSelectedABWDetails"
              :showLabel="true"
              size="mini"
            ></er-button>
            <er-button
              btnType="cancel"
              :showLabel="true"
              size="mini"
              :disabled="isReadOnly"
              @click="cancelSelectedABWDetails"
            ></er-button>
          </el-row>
        </er-data-tables>
      </el-row>
      <span v-if="harvestedPonds.length > 0">
        <el-alert
          :title="
            `${$t('ABW_hrvst_completed_ponds_list')}: ${harvestedPonds.length}`
          "
          type="info"
          show-icon
          :closable="false"
        >
          <h4>{{ harvestedPonds.join(", ") }}</h4>
        </el-alert>
        <el-col>
          <el-row class="abbr-row">
            <h6>
              {{ $t('ABW_abbr') }}
            </h6>
          </el-row>
        </el-col>
      </span>
      <el-row class="abbr-row">
            <h6>
              {{ $t('abw_note') }}
            </h6>
          </el-row>
    </el-row>
  </layout-pondlogs>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import samplesTable2 from "./samplesTable2";
import Loader from "@/components/base/Loader";
import filtersMixin from "@/mixins/filtersMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import notificationMixin from "@/mixins/notificationMixin";
export default {
  mixins: [
    errorHandlerMixin,
    filtersMixin,
    datesHandlerMixin,
    userPreferenceMixin,
    notificationMixin
  ],
  props: ["permissions"],
  components: {
    samplesTable2,
    Loader
  },
  data: function() {
    return {
      currOpenedPopoverId: undefined,
      pondsInLocation: [],
      abwDate: "",
      selectedoption: false,
      abwObjects: {},
      loading: false,
      pondsHavingABW: [],
      searchPond: "",
      previous_abw: [],
      prevAbwPonds: [],
      tableData: [],
      tableProps: {
        size: "small",
        height: "calc(100vh - 192px)",
        defaultSort: {
          prop: "pond_title",
          order: "ascending"
        }
      },
      query: { limit: 1, page: 1, location_id: "" },
      selectedRecordsData: [],
    };
  },
  watch: {
    searchPond: function (newVal, oldVal) {
      this.handelSearch()
    }
  },
  computed: {
    ...mapGetters("user", {
      getUserLocations: "getUserLocations",
      getCurrUserLocation: "getCurrUserLocation",
      getUserTimeZoneOffset: "getUserTimeZoneOffset",
      getPreferredUnits: "getPreferredUnits",
      isReadOnly: "isReadOnly"
    }),
    ...mapGetters("pond", {
      getPonds: "getPonds",
      getMapPondidPond: "getMapPondidPond",
      getPondsObj: "getPondsObj",
      getPondIDsHavingABW: "getPondIDsHavingABW",
      getpondHavingABWWithAbwValues: "getpondHavingABWWithAbwValues"
    }),
    tableDataUpdated() {
      return this.tableData;
    },
    checkAll: function() {
      return this.tableData.every((abwRecord) => abwRecord.selected === true);
    },
    isIndeterminate: function() {
      return (
        this.tableData.some((abwRecord) => abwRecord.selected === true) &&
        !this.checkAll
      );
    },
    hasUpdatePermissions() {
      return this.getUpdatePermissions.MANAGE_ABW;
    },
    getLocationId() {
      return this.getCurrUserLocation._id;
    },
    pickerOptions: function() {
      return {
        disabledDate: function(date) {
          return date.getTime() > Date.now();
        }
      };
    },
    sortMethodForColumns: function() {
      return {
        pond_title: this.$commonUtils.alphaNumericComparator
      };
    },
    getDateObjForSelectedDate: function() {
      const utcDate = (this.abwDate || "1980-12-31") + "T00:00:00.000Z";
      return this.dhm__castUserUTCToUTCDateObj(utcDate);
    },
    filterPondHavingABW() {
      const selectedDate = this.getDateObjForSelectedDate;
      return Object.values(this.getpondHavingABWWithAbwValues).reduce(
        (acc, value) => {
          const stockingDate = this.dhm__castUserUTCToUTCDateObj(
            value.cultivation_date
          );
          if (
            this.dhm__dateUtilsLib.isEqual(stockingDate, selectedDate) ||
            this.dhm__dateUtilsLib.isBefore(stockingDate, selectedDate)
          ) {
            acc.push(value);
          }
          return acc;
        },
        []
      );
    },
    tableColumns() {
      return {
        selected: {
          label: "",
          prop: "selected",
          width: "50px"
        },
        pond_name: {
          label: this.$t("Comn_pond_name"),
          prop: "pond_title",
          sortable: "custom",
          enableSearch: true,
          searchProperty: "pond_title"
        },
        days_of_culture: {
          label: this.$t("Comn_doc_days"),
          prop: "days_of_culture",
          sortable: false
        },

        last_abw: {
          label: `${this.$t("ABW_last_abw")} (g)`,
          prop: "last_abw",
          sortable: true
        },
        last_abw_date: {
          label: this.$t("ABW_last_abw_date"),
          prop: "last_abw_date",
          sortable: true
        },
        samples: {
          label: this.$t("Comn_samples"),
          prop: "samples",
          nested: true,
          nestedColumns: {
            weight_g: {
              label: this.$t("Comn_weight_g"),
              prop: "weight_g"
            }
          }
        },
        abw_g: {
          label: this.$t("Comn_abw_g"),
          prop: "abw",
          sortable: true
        },
        weight_gain: {
          label: this.$t("Comn_wg_g"),
          prop: "weight_gain",
          sortable: true
        },
        survival_percentage: {
          label: this.$t("Comn_survival_percentage"),
          prop: "survival_percentage",
          sortable: true
        }
      };
    },
    harvestedPonds() {
      const ponds = this.getPonds;
      const pondsInHarvest = [];
      ponds.forEach((pond) => {
        const dateDiff = this.dhm__dateUtilsLib.differenceInDays(
          ...[`${this.abwDate}T00:00:00.000Z`, pond.cultivation_date].map((x) =>
            this.dhm__dateUtilsLib.zonedTimeToUtc(x, this.getUserTimeZoneString)
          )
        );
        if (pond.status === "INACTIVE" || dateDiff < 0) {
          if (this.getPondIDsHavingABW.indexOf(pond._id) > -1) {
            pondsInHarvest.push(pond);
          }
        }
      });
      return pondsInHarvest
        .map((pond) => pond.title)
        .sort((a, b) => this.$commonUtils.alphaNumericComparator(a, b));
    },
    // tableData() {
    //   return this.searchPond !== ""
    //     ? this.pondsHavingABW.filter((x) =>
    //         x.pond_title.toLowerCase().includes(this.searchPond.toLowerCase())
    //       )
    //     : this.pondsHavingABW;
    // },
    selectedABWRecords: function() {
      return this.tableData.filter((x) => x.selected);
      // return this.selectedRecordsData.filter((x) => x.selected);
    }
  },
  async mounted() {
    this.initDate();
    await this.initComponent();
  },
  methods: {
    ...mapActions("pond", {
      fetchAllPonds: "fetchAllPonds",
      fetchABWSummary: "fetchABWSummary"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handelSearchInput() {
      if (this.searchPond !== '') {
        this.mixPanelEventGenerator({ eventName: "ABW Table - Search" });
      }
    },
    handelCalculate(data) {
      this.mixPanelEventGenerator({ eventName: "ABW Table - Sample - Add" });
      this.handleChangeABW(data)
    },
    handelSearch() {
      this.tableData = this.searchPond !== ""
        ? this.pondsHavingABW.filter((x) =>
            x.pond_title.toLowerCase().includes(this.searchPond.toLowerCase())
          )
        : this.pondsHavingABW;
        return this.tableData;
    },
    getDOC(pondCultivationDate) {
      return (
        this.dhm__dateUtilsLib.differenceInDays(
          this.getDateObjForSelectedDate,
          this.dhm__castUserUTCToUTCDateObj(pondCultivationDate)
        ) + 1
      );
    },
    initDate() {
      this.abwDate = this.dhm__dateUtilsLib.formatTZWithLocale(
        this.dhm__dateUtilsLib.startOfDay(this.dhm__getTodayInUserTZ),
        "yyyy-MM-dd"
      );
    },
    getABWLastDate(date) {
      const utc = this.dhm__castUserUTCToUTCDateObj(date);
      return this.dhm__dateUtilsLib.formatTZWithLocale(
        this.dhm__dateUtilsLib.utcToZonedTime(utc, this.getUserTimeZoneString),
        this.upm__getFormatDateAndTimeString,
        this.getUserTimeZoneString,
        this.$i18n.locale
      );
    },
    getValuetoFixed(abw) {
      return abw.toFixed(2);
    },
    handleOpenedPopover(openedPopoverPondId) {
      if (this.currOpenedPopoverId === undefined) {
        this.currOpenedPopoverId = openedPopoverPondId;
      } else if (this.currOpenedPopoverId !== openedPopoverPondId) {
        const foundIndex = this.pondsHavingABW
          .map((x) => x.pond_id)
          .indexOf(openedPopoverPondId);
        const abwRec = this.pondsHavingABW[foundIndex];
        this.$set(abwRec, "isPopUpVisible", false);
        this.currOpenedPopoverId = openedPopoverPondId;
      }
    },
    async initComponent() {
      this.loading = true;
      try {
        this.pondsHavingABW = [];
        this.selectedRecordsData = [];
        await Promise.all([
          this.fetchAllPonds({
            location_id: this.getCurrUserLocation._id,
            status: ["ACTIVE", "INACTIVE"],
            include: ["title", "status", "cultivation_date"],
            get_all: true
          }),
          this.fetchABWSummary({
            date: `${this.abwDate}T00:00:00.000Z`,
            location_id: this.getCurrUserLocation._id,
            get_all: true
          })
        ]);
        this.pondsHavingABW = this.filterPondHavingABW
          .filter((x) => this.harvestedPonds.indexOf(x) === -1)
          .map((x) => {
            x.isPopUpVisible = false;
            x.present_date = this.abwDate;
            x.initial_abw = x.abw;
            x.pond_title = (
              this.getPondsObj[x.pond_id] || { title: this.$t("empty") }
            ).title;
            x.selected = false;
            return this.$lodash.cloneDeep(x);
          });
        (this.pondsHavingABW || []).forEach((abw) => {
          this.calcWeightGain(abw, false);
        });
        this.tableData = this.searchPond !== "" ? this.handelSearch() : this.pondsHavingABW;
        this.pondsInLocation = this.getPonds;
        this.query.location_id = this.getCurrUserLocation._id;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.abwtable) {
            this.$refs.abwtable.$refs.dataTables.$refs.elTable.doLayout();
          }
        });
        this.loading = false;
      }
    },
    async prevDayClick(date) {
      this.abwDate = date;
      this.mixPanelEventGenerator({ eventName: "ABW Table - Date Filter" });
      await this.initComponent();
    },
    async nextDayClick(date) {
      this.abwDate = date;
      this.mixPanelEventGenerator({ eventName: "ABW Table - Date Filter" });
      await this.initComponent();
    },
    saveSelectedABWDetails: async function() {
      const errors = [];
      try {
        this.$gblUAMCanUserEdit(this.permissions);
        let abwPonds = "";
        let survivalPondsGT = "";
        let survivalPondsLT = "";
        console.log("this.selectedABWRecords", this.selectedABWRecords);
        const ponds = this.selectedABWRecords.reduce((acc, curr) => {
          console.log("curr", curr);
          if (curr.abw <= 0 || !curr.abw) {
            if (abwPonds.length > 0) {
              abwPonds = abwPonds + ", " + curr.pond_title;
            } else {
              abwPonds = curr.pond_title;
            }
            // errors.push({
            //   message: `${this.$tc("Comn_must_greater_than_for", 2, {
            //     field: this.$t("ABW_abw"),
            //     threshold: 0
            //   })} ${curr.pond_title}`
            // });
          }
          if (curr.survivalChange) {
            this.mixPanelEventGenerator({ eventName: "ABW Table - Survival % - Change" });
          }
          if (curr.survival_percentage > 200) {
            if (survivalPondsGT.length > 0) {
              survivalPondsGT = survivalPondsGT + ", " + curr.pond_title;
            } else {
              survivalPondsGT = curr.pond_title;
            }
          }
          if (curr.survival_percentage === 0) {
            if (survivalPondsLT.length > 0) {
              survivalPondsLT = survivalPondsGT + ", " + curr.pond_title;
            } else {
              survivalPondsLT = curr.pond_title;
            }
          }
          if (errors.length > 0) {
            throw {
              type: "FAIL_TO_SAVE",
              errors: errors
            };
          }
          if (curr.survival_percentage > 100) {
            console.log("survival", curr);
            if (acc.length > 0) {
              acc = acc + ", " + curr.pond_title;
            } else {
              acc = curr.pond_title;
            }
          }
          return acc;
        }, "");
        console.log("data", ponds);
        if (abwPonds.length > 0) {
          errors.push({
            message: `${this.$tc("Comn_must_greater_than_for", 2, {
              field: this.$t("ABW_abw"),
              threshold: 0
            })} ${abwPonds}`
          });
        }
        if (survivalPondsLT.length > 0) {
          errors.push({
            message: `${this.$tc("Comn_must_greater_than_for", 2, {
              field: this.$t("Comn_survival_percentage"),
              threshold: 0
            })} ${survivalPondsLT}`
          });
        }
        if (survivalPondsGT.length > 0) {
          errors.push({
            message: `${this.$tc("Comn_must_less_than_threshold_for_symbol", 2, {
              field: this.$t("Comn_survival_percentage"),
              threshold: 200
            })} ${survivalPondsGT}`
          });
        }
        if (errors.length > 0) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: errors
          };
        }
        if (ponds.length > 0) {
          const confirmMessage = ` ${this.$tc("Comn_servival_error", 1, {
            ponds: ponds
          })}`;
          await this.$confirm(confirmMessage, "Warning", {
            confirmButtonText: this.$t("Comn_confirm"),
            cancelButtonText: this.$t("Comn_cancel"),
            type: "warning"
          }).then(async () => {
            await this.savingAbwData();
            this.mixPanelEventGenerator({ eventName: "ABW Table - Save" });
          });
        } else {
          await this.savingAbwData();
          this.mixPanelEventGenerator({ eventName: "ABW Table - Save" });
        }
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        // await this.initComponent();
        this.loading = false;
        this.prevAbwPonds = [];
        this.previous_abw = [];
      }
    },
    async savingAbwData() {
      const errors = [];
      if (this.pondsHavingABW.length === 0) {
        throw {
          type: "FAIL_TO_SAVE",
          errors: [
            {
              message: this.$t("Comn_no_records_found")
            }
          ]
        };
      }
      const payload = this.selectedABWRecords.map(
        ({
          pond_id,
          pond_title,
          present_date,
          abw,
          harvested_abw,
          samples,
          survival_percentage
        }) => {
          const date = `${present_date}T00:00:00.000Z`;
          // if (abw <= 0) {
          //   errors.push({
          //     message: `${pond_title} ${this.$tc("Comn_must_greater_than", 2, {
          //       field: this.$t("ABW_abw"),
          //       threshold: 0
          //     })}`
          //   });
          // }
          // if (survival_percentage > 200) {
          //   errors.push({
          //     message: `${pond_title} ${this.$tc("Comn_must_greater_than", 2, {
          //       field: this.$t("Comn_survival_percentage"),
          //       threshold: 0
          //     })}`
          //   });
          // }
          const aggAbw = samples.reduce(
            (acc, x) => {
              acc[0] += x.weight;
              acc[1] += x.no_of_shrimps;
              return acc;
            },
            [0, 0]
          );
          if (aggAbw[0] > 0 && aggAbw[1] > 0) {
            return { pond_id, date, abw, samples, survival_percentage };
          }
          if (harvested_abw) {
            return { pond_id, date, abw };
          }
          return { pond_id, date, abw, survival_percentage };
        }
      );
      console.log("payload", payload);
      if (payload.length === 0) {
        throw {
          type: "FAIL_TO_SAVE",
          errors: [
            {
              message: this.$t("Comn_select_atleast_item", {
                item: this.$t("ABW_abw")
              })
            }
          ]
        };
      }
      if (errors.length > 0) {
        throw {
          type: "FAIL_TO_SAVE",
          errors: errors
        };
      }
      console.log(this.previous_abw);
      this.previous_abw.forEach((item) => {
        if (item.initial_abw) {
          this.prevAbwPonds.push(item.pond_title);
        }
      });

      if (this.prevAbwPonds.length) {
        console.log(this.prevAbwPonds, this.abwDate);
        await this.nofm__generalConfirmation(
          this.dhm__formatTZ(
            this.dhm__dateUtilsLib.parseISO(this.abwDate),
            `${this.upm__getFormatDateString}`
          ),
          this.prevAbwPonds
            .map((x) => x[0].toUpperCase() + x.substring(1))
            .join(","),
          this.$t("ABW_abw")
        );
        this.loading = true;
        await this.$store.dispatch("pond/saveABWValues", payload);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Abw_created_success"),
          duration: 5000,
          type: "success"
        });
        await this.initComponent();
      } else {
        this.loading = true;
        await this.$store.dispatch("pond/saveABWValues", payload);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Abw_created_success"),
          duration: 5000,
          type: "success"
        });
        await this.initComponent();
      }
    },
    cancelSelectedABWDetails: async function() {
      await this.initComponent();
    },
    updateFields: function(abwObj, event = "") {
      this.calcABW(abwObj);
      this.calcWeightGain(abwObj);
      if (event === "calculate") abwObj.isPopUpVisible = !abwObj.isPopUpVisible;
    },
    handleChangeABW(input, type = '') {
      this.tableData = this.tableData.reduce((acc, curr) => {
        if (input.pond_id === curr.pond_id) {
          curr = input;
          curr.survivalChange = type === 'survival'
          curr.selected = type === 'checkBox' ? curr.selected : true
        }
        acc.push(curr);
        return acc;
      }, []);
    },
    async handleChangeInABW(changedABWValue, abwRecord) {
      abwRecord.modified = true;
      this.previous_abw.push(abwRecord);
      const agg = abwRecord.samples.reduce(
        (acc, x) => {
          if (!isFinite(+x.no_of_shrimps) || !isFinite(x.weight)) return acc;
          acc[0] += x.no_of_shrimps;
          acc[1] += x.weight;
          return acc;
        },
        [0, 0]
      );
      this.calcWeightGain(abwRecord);
      if (agg[0] === 0 && agg[1] === 0) return;
      abwRecord.samples.forEach((sample) => {
        sample.abw = changedABWValue;
        sample.no_of_shrimps = sample.no_of_shrimps || 1;
        sample.weight = changedABWValue * sample.no_of_shrimps;
      });
    },
    calcABW: function(abwObj) {
      let totalWeight = 0;
      let totalSamples = 0;
      abwObj.samples.forEach((sample) => {
        sample.abw = sample.weight / sample.no_of_shrimps;
        totalWeight += sample.weight;
        totalSamples += sample.no_of_shrimps;
      });
      if (totalWeight) {
        abwObj.abw = totalWeight / totalSamples;
      } else {
        abwObj.abw = 0;
      }
      return abwObj.abw;
    },
    calcWeightGain: function(abwObj, modified = true) {
      if (modified) {
        abwObj.modified = true;
      }
      if (abwObj.abw > 0) {
        abwObj.weight_gain = Number(
          (abwObj.abw - (abwObj.last_abw || 0)).toFixed(2)
        );
      } else {
        abwObj.weight_gain = 0;
      }

      return abwObj.weight_gain;
    },
    handlePresentDateChange: async function(date) {
      this.abwDate = date;
      this.mixPanelEventGenerator({ eventName: "ABW Table - Date Filter" });
      await this.initComponent();
    },
    handleSampleTablePopverCancel(abwObj) {
      const foundIndex = this.pondsHavingABW
        .map((x) => x.pond_id)
        .indexOf(abwObj.pond_id);
      this.pondsHavingABW.splice(foundIndex, 1, abwObj);
    },
    handleChangeInColumnSelection(selectedColumns) {
      const columnObj = selectedColumns.reduce((acc, curr) => {
        acc[curr] = this.tableColumns[curr];
        return acc;
      }, {});
      this.selectedHeaders = Object.assign({}, columnObj);
    },
    handleSelectAll(value) {
        this.tableData = this.tableData.reduce((acc, curr) => {
          curr.selected = value;
          acc.push(curr);
          return acc;
        }, []);
    }
  }
};
</script>

<style lang="scss">
.abw-action-items {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: flex-start;
  // margin: 0px 10px 10px 10px;
  & > div:nth-child(n + 1) {
    margin-right: 10px;
  }
}

.culture-abw {
  @include responsiveProperty(--table-gutter, 167px, 170px, 186px);
  h3 {
    font-weight: 700;
    font-size: 20px;
  }
  // .day-picker-container {
  //   @include responsiveProperty(width, 100px, 115px, 140px);
  // }
  .er-button.er-button--primary {
    border-radius: 12px;
    height: 27px;
    line-height: 0.5;
  }
  .er-data-tables .pagination-bar .el-pagination button.el-button--primary {
    background: #409eff;
    color: white;
    margin-left: 10rem;
    width: 80px;
  }

  .er-button.er-button--danger {
    border-radius: 12px;
    height: 27px;
    line-height: 0.5;
  }
  .er-data-tables .pagination-bar .el-pagination button.el-button--danger {
    background: #f56c6c;
    color: white;
    // margin-left: 10rem;
    width: 80px;
  }
  .day-picker-top {
    display: flex;

    .el-input--small .el-input__icon {
      line-height: 0px;
    }
    .el-input__icon {
      height: 88%;
    }
    .el-input__inner {
      border: 0px solid #dcdfe6 !important;
      padding-right: 0px;
      border-radius: 8px;
    }
    img {
      width: 11px;
      &:hover {
        cursor: pointer;
      }
    }
    .prev-day-icon {
      margin-left: 2px;
    }
    .next-day-icon {
      margin-left: 8px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100px;
    }
  }
  &.culture-abw-scroll {
    height: 100%;
  }
  .abbr-row {
    line-height: 3;
  }
  .culture-abw-scroll-view {
    padding-right: 10px;
  }
  .culture-abw-scroll-wrap {
    height: 100%;
    overflow-x: auto;
  }
  .input_survival_per,
  .input_abw {
    width: 80px;
    .el-input__inner {
      text-align: center;
    }
  }
  .el-pagination__sizes {
    .el-input__inner {
      height: 23px;
      line-height: 23px;
    }
  }
}
</style>
