<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondScheduleGraph.vue
Description: This file is the chart component that displays amount of feed dispensed in pond during the culture. Here x axis is the feeding date and y axis is the amount of feed dispensed during the culture.
-->
<template>
  <er-card
    v-loading="loading"
    element-loading-background="white"
    ref="pond-schedule-graph"
    class="pond-schedule-graph"
  >
    <layout-toolbar slot="header" gap="5">
      <p class="card-title">
        {{ `${$tc("Comn_pond", 1)} ${$t("Comn_cumu_feed")}` }}
      </p>
      <div class="filler"></div>
      <el-switch
        v-model="feedAreaSwitch"
        active-color="#0A2463"
        :active-text="getLabelByUserPreferences"
        @change="handelChangeSwitch"
      >
      </el-switch>
      <er-date-picker
        v-model="dateRange"
        type="daterange"
        unlink-panels
        :timeZoneString="getUserTimeZoneString"
        :disableDateMethod="chm__disableDateMethod"
        :availableInterval="chm__availableInterval"
        :clearable="false"
        @change="handleValueChange($event, 'dateChange')"
        :format="this.upm__getFormatDateString"
      ></er-date-picker>
    </layout-toolbar>
    <el-row :key="$i18n.locale">
      <high-charts
        ref="highcharts"
        :options="feedAreaSwitch ? feedAreaChartOptions : chartOptions"
        constructor-type="stockChart"
      ></high-charts>
    </el-row>
  </er-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import chartMixin from "@/mixins/chartMixin";
import {
  pondScheduleGraphConfig,
  pondScheduleFeedAreaGraphConfig
} from "./chartOptions";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
export default {
  mixins: [
    errorHandlerMixin,
    chartMixin,
    datesHandlerMixin,
    userPreferenceMixin
  ],
  data: function() {
    return {
      loading: false,
      dateRange: [],
      params: {
        from_date: "",
        to_date: "",
        month_of: null,
        week_of: null,
        only_feed_meta_data: true
      },
      feedAreaSwitch: false,
      chartOptions: pondScheduleGraphConfig,
      feedAreaChartOptions: pondScheduleFeedAreaGraphConfig,
      pondSlotFeedLogs: [],
      dateFormatObject: {
        "dd/MM/yyyy": "%d/%m/%Y",
        "dd MMM": "%d %b",
        "dd MMM, yy": "%d %b, %y"
      }
    };
  },
  computed: {
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits"
    }),
    ...mapGetters("pondDetails", {
      getPondScheduleGraphSlotWiseFeed: "getPondScheduleGraphSlotWiseFeed",
      getPondScheduleGraphSlotWiseManualFeed: "getPondScheduleGraphSlotWiseManualFeed",
      getSelectedPondWithDeviceDetails: "getSelectedPondWithDeviceDetails"
    }),
    ...mapGetters("dashboard", {
      getFeedConsumptionData: "getFeedConsumptionData"
    }),
    chm__defaultDateObjDtRangeForCurrCulture() {
      return this.chm__getDateObjArrOfCultureDateRange;
    },
    FeedingLevelTranslations() {
      return (feeding_level) => {
        const translations = {
          NORMAL: this.$t("PM_normal"),
          ECONOMICAL: this.$t("PM_economical"),
          AGGRESSIVE: this.$t("PM_Aggressive"),
          HYBRID: this.$t("PM_hybrid"),
          NONE: ""
          }
        return translations[feeding_level];
      }
    },
    getLabelByUserPreferences() {
      const areaToKeyMap = {
        ac: "Kg/Ac",
        ha: "Kg/Ha"
      };
      return areaToKeyMap[this.getPreferredUnits.pond_area_units];
    }
  },
  mounted() {
    this.$root.$on("handle-laungage-chnage", () => {
      this.initComponent("");
    });
    this.chm__registerResizeObserver("pond-schedule-graph");
  },
  beforeDestroy() {
    this.chm__unRegisterResizeObserver("pond-schedule-graph");
  },
  watch: {
    feedAreaSwitch(val) {
      // if (val) {
      this.chm__setAxisText();
      // }
    }
  },
  methods: {
    ...mapActions("pondDetails", {
      fetchSlotWiseFeed: "fetchSlotWiseFeed"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handelChangeSwitch() {
      this.mixPanelEventGenerator({ eventName: "Ponds - Dashboard - Ponds Cumulative Feed Graph - Kg/Ac Toggle" });
    },
    async initComponent(pondId) {
      this.loading = true;
      const yAxis = this.feedAreaChartOptions
        ? "feed-area"
        : "PM_dispensed_feed_kg";
      try {
        await this.fetchSlotWiseFeed({
          deviceType: "pond",
          params: this.params
        });
        this.pondSlotFeedLogs = this.getPondScheduleGraphSlotWiseFeed;
        console.log("yAxis", yAxis);
        this.chm__initAxisTextKeys("Comn_date", yAxis);
        this.chm__setAxisText();
        this.chm__initChartLang();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    chm__setAxisText() {
      if (this.feedAreaSwitch) {
        this.chartOptions.yAxis.title.text =
          this.$t("feed-area") +
          " " +
          "(" +
          this.getLabelByUserPreferences +
          ")";
      } else {
        this.chartOptions.yAxis.title.text = this.$t("PM_dispensed_feed_kg");
      }
    },
    pondAreaConversion() {
      return this.getSelectedPondWithDeviceDetails.units === 'ha' ? this.getSelectedPondWithDeviceDetails.size * 2.47105 : this.getSelectedPondWithDeviceDetails.size
    },
    pondScheduleWiseFeed(pondFeedDaysWise, dateRange) {
      const milliSecs = (date) => date.getTime();
      const dateScheduleWiseFeed = {};
      const dateScheduleWiseFeedingLevel = {};
      const dateScheduleWiseDispFeed = {};
      const dateScheduleWiseManualFeed = {};
      const dateScheduleWiseFeedArea = {};
      const dateScheduleWiseFeedLimitArea = {};
      let dayStart = this.dhm__dateUtilsLib.parseISO(
        dateRange[0] + "T00:00:00.000Z"
      );
      const dayEnd = this.dhm__dateUtilsLib.parseISO(
        dateRange[1] + "T00:00:00.000Z"
      );
      while (dayStart <= dayEnd) {
        dateScheduleWiseFeed[milliSecs(dayStart)] = 0;
        dateScheduleWiseFeedingLevel[milliSecs(dayStart)] = '';
        dateScheduleWiseDispFeed[milliSecs(dayStart)] = 0;
        dateScheduleWiseManualFeed[milliSecs(dayStart)] = 0;
        dateScheduleWiseFeedArea[milliSecs(dayStart)] = { ac: 0, hc: 0 };
        dateScheduleWiseFeedLimitArea[milliSecs(dayStart)] = {
          feed_limit_ac: 0,
          feed_limit_hc: 0
        };
        dayStart = this.dhm__dateUtilsLib.add(dayStart, { days: 1 });
      }
      pondFeedDaysWise.forEach((dayFeed) => {
        const date = this.dhm__dateUtilsLib.parseISO(dayFeed.date);
        dateScheduleWiseFeed[milliSecs(date)] = Number(dayFeed.feed.toFixed(2));
        dateScheduleWiseDispFeed[milliSecs(date)] = Number(
          dayFeed.dispensed_feed.toFixed(2)
        );
        dateScheduleWiseFeedingLevel[milliSecs(date)] = this.FeedingLevelTranslations(dayFeed.feeding_level);
        dateScheduleWiseFeedArea[milliSecs(date)] = {
          ac: Number(dayFeed.feed_per_acre.toFixed(2)),
          hc: Number(dayFeed.feed_per_hectare.toFixed(2))
        };
        dateScheduleWiseFeedLimitArea[milliSecs(date)] = {
          feed_limit_ac: Number(dayFeed.feed_limit_per_acre.toFixed(2)),
          feed_limit_hc: Number(dayFeed.feed_limit_per_hectare.toFixed(2))
        };
      });
      this.getPondScheduleGraphSlotWiseManualFeed.forEach((dayFeed) => {
        const date = this.dhm__dateUtilsLib.parseISO(dayFeed.date);
        dateScheduleWiseManualFeed[milliSecs(date)] = Number(dayFeed.feed_dispensed);
      });
      let scheduleFeed = Object.keys(dateScheduleWiseFeed).map((key) => {
        return {
          x: Number(key),
          y: dateScheduleWiseFeed[key] + dateScheduleWiseManualFeed[key]
        };
      });
      let scheduleDispensedFeed = Object.keys(dateScheduleWiseDispFeed).map(
        (key) => {
          return {
            x: Number(key),
            y: dateScheduleWiseDispFeed[key] + dateScheduleWiseManualFeed[key],
            z: dateScheduleWiseManualFeed[key],
            Z: dateScheduleWiseDispFeed[key],
            Y: dateScheduleWiseFeedingLevel[key]
          };
        }
      );
      let feedAreaObject = Object.keys(dateScheduleWiseFeedArea).map((key) => {
        return {
          x: Number(key),
          y:
            this.getPreferredUnits.pond_area_units === "ac"
              ? (dateScheduleWiseFeedArea[key].ac + (dateScheduleWiseManualFeed[key] / this.pondAreaConversion()))
              : (dateScheduleWiseFeedArea[key].hc + (dateScheduleWiseManualFeed[key] / this.pondAreaConversion())),
          z: this.getPreferredUnits.pond_area_units === "ac"
              ? (dateScheduleWiseManualFeed[key] / this.pondAreaConversion()).toFixed(2)
              : (dateScheduleWiseManualFeed[key] / this.pondAreaConversion()).toFixed(2),
          Z:
            this.getPreferredUnits.pond_area_units === "ac"
              ? dateScheduleWiseFeedArea[key].ac
              : dateScheduleWiseFeedArea[key].hc,
          Y: dateScheduleWiseFeedingLevel[key]
        };
      });
      let feedLimitAreaObject = Object.keys(dateScheduleWiseFeedLimitArea).map(
        (key) => {
          return {
            x: Number(key),
            y:
              this.getPreferredUnits.pond_area_units === "ac"
                ? (dateScheduleWiseFeedLimitArea[key].feed_limit_ac + (dateScheduleWiseManualFeed[key] / this.pondAreaConversion()))
                : (dateScheduleWiseFeedLimitArea[key].feed_limit_hc + (dateScheduleWiseManualFeed[key] / this.pondAreaConversion())),
            z: this.getPreferredUnits.pond_area_units === "ac"
              ? (dateScheduleWiseManualFeed[key] / this.pondAreaConversion()).toFixed(2)
              : (dateScheduleWiseManualFeed[key] / this.pondAreaConversion()).toFixed(2),
            Z:
              this.getPreferredUnits.pond_area_units === "ac"
                ? dateScheduleWiseFeedLimitArea[key].feed_limit_ac
                : dateScheduleWiseFeedLimitArea[key].feed_limit_hc,
            Y: dateScheduleWiseFeedingLevel[key]
          };
        }
      );
      scheduleFeed = scheduleFeed.sort((a, b) => a.x - b.x);
      feedAreaObject = feedAreaObject.sort((a, b) => a.x - b.x);
      feedLimitAreaObject = feedLimitAreaObject.sort((a, b) => a.x - b.x);
      scheduleDispensedFeed = scheduleDispensedFeed.sort((a, b) => a.x - b.x);
      return {
        scheduleFeed,
        scheduleDispensedFeed,
        feedAreaObject,
        feedLimitAreaObject
      };
    },
    chm__initChart() {
      let result = {};
      let feedLimit = [];
      let feedAreaObject = [];
      let feedLimitAreaObject = [];
      let dispensedLimit = [];
      let totalFeedLimit = 0;
      let totalDispensedLimit = 0;
      let totalFeedLimitArea = 0;
      let totalDispensedLimitArea = 0;
      if (this.pondSlotFeedLogs && this.pondSlotFeedLogs.length === 0) {
        this.chartOptions.series = [];
        this.feedAreaChartOptions.series = [];
        return;
      } else {
        // const res = this.getFeedConsumptionData;
        // console.log("res", res);
        if (this.pondSlotFeedLogs) {
          result = this.pondScheduleWiseFeed(this.pondSlotFeedLogs, [
            this.params.from_date,
            this.params.to_date
          ]);
        } else {
          return;
        }
        console.log("result", result);
        feedLimit = result.scheduleFeed;
        feedAreaObject = result.feedAreaObject;
        feedLimitAreaObject = result.feedLimitAreaObject;
        dispensedLimit = result.scheduleDispensedFeed;
        totalFeedLimit = Object.values(feedLimit)
          .map((feed) => feed.y)
          .reduce((acc, curr) => acc + curr);
        totalDispensedLimit = Object.values(dispensedLimit)
          .map((feed) => feed.y)
          .reduce((acc, curr) => acc + curr);
        totalFeedLimitArea = Object.values(feedLimitAreaObject)
          .map((feed) => feed.y)
          .reduce((acc, curr) => acc + curr);
        totalDispensedLimitArea = Object.values(feedAreaObject)
          .map((feed) => feed.y)
          .reduce((acc, curr) => acc + curr);
      }
      this.initChartSeries(
        feedLimit,
        dispensedLimit,
        totalFeedLimit,
        totalDispensedLimit,
        feedAreaObject,
        feedLimitAreaObject,
        totalFeedLimitArea,
        totalDispensedLimitArea
      );
    },
    initChartSeries(
      feedLimit,
      dispensedLimit,
      totalFeedLimit,
      totalDispensedLimit,
      feedAreaObject,
      feedLimitAreaObject,
      totalFeedLimitArea,
      totalDispensedLimitArea
    ) {
      this.chm__setAxisText();
      this.chartOptions.tooltip.xDateFormat = this.dateFormatObject[
        this.upm__getFormatDateString
      ];
      this.feedAreaChartOptions.tooltip.xDateFormat = this.dateFormatObject[
        this.upm__getFormatDateString
      ];
      this.chartOptions.series = [];
      this.feedAreaChartOptions.series = [];
      if (feedLimit.length === 0 && this.dispensedLimit.length === 0) {
        return;
      }
      this.chartOptions.series.push({
        name: {
          legend: `${this.$t("PM_day_feed")} (${this.$t(
            "Comn_total"
          )}: ${totalFeedLimit.toFixed(2)})`,
          tooltip: this.$t("PM_day_feed")
        },
        data: feedLimit,
        type: "area",
        color: "#7cb5ec",
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "rgba(124, 181, 236,1)"],
            [1, "rgba(255,255,255,.25)"]
          ]
        },
        visible: false
      });
      this.feedAreaChartOptions.series.push({
        name: {
          legend: `${this.$t("feed-limit-area")} (${this.$t(
            "Comn_total"
          )}: ${totalFeedLimitArea.toFixed(2)})`,
          tooltip: this.$t("feed-limit-area")
        },
        data: feedLimitAreaObject,
        type: "area",
        color: "#7cb5ec",
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "rgba(124, 181, 236,1)"],
            [1, "rgba(255,255,255,.25)"]
          ]
        },
        visible: false
      });
      this.chartOptions.series.push({
        name: {
          legend: `${this.$t("PM_dispensed")} (${this.$t(
            "Comn_total"
          )}: ${totalDispensedLimit.toFixed(2)})`,
          tooltip: this.$t("PM_dispensed"),
          tooltipName: this.$t('Comn_manual_feed'),
          tooltipName2: this.$t('Comn_pond_mother') + ' ' + this.$t('Comn_feed'),
          tooltipName3: this.$t('Comn_feeding_level'),
        },
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: "<small>{point.key}</small><table>",
          pointFormat:
            '<tr><td style="color: {series.color}">{series.name.tooltipName}: </td>' +
            '<td style="text-align: right"><b>{point.z}</b></td></tr><tr><td style="color: {series.color}">{series.name.tooltipName2}: </td>' +
            '<td style="text-align: right"><b>{point.Z}</b></td></tr><tr><td style="color: {series.color}">{series.name.tooltip}: </td><td style="text-align: right"><b>{point.y}</b></td></tr>' +
            '<tr><td style="color: {series.color}">{series.name.tooltipName3}: </td><td style="text-align: right"><b>{point.Y}</b></td></tr>',
          footerFormat: "</table>",
          valueDecimals: 2,
          split: false,
          // xDateFormat: CHART_DATE_FORMATS.dmy
          xDateFormat:
            this.dateFormatObject[this.upm__getFormatDateString]
        },
        data: dispensedLimit,
        type: "area",
        color: "#f7a35c",
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "rgba(247, 163, 92,1)"],
            [1, "rgba(255,255,255,.25)"]
          ]
        },
        visible: true
      });
      this.feedAreaChartOptions.series.push({
        name: {
          legend: `${this.$t("dispensed-area")} (${this.$t(
            "Comn_total"
          )}: ${totalDispensedLimitArea.toFixed(2)})`,
          tooltip: this.$t("dispensed-area"),
          tooltipName: this.$t('Comn_manual_feed'),
          tooltipName2: this.$t('Comn_pond_mother') + ' ' + this.$t('Comn_feed'),
          tooltipName3: this.$t('Comn_feeding_level'),
        },
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: "<small>{point.key}</small><table>",
          pointFormat:
            '<tr><td style="color: {series.color}">{series.name.tooltipName}: </td>' +
            '<td style="text-align: right"><b>{point.z}</b></td></tr><tr><td style="color: {series.color}">{series.name.tooltipName2}: </td>' +
            '<td style="text-align: right"><b>{point.Z}</b></td></tr><tr><td style="color: {series.color}">{series.name.tooltip}: </td><td style="text-align: right"><b>{point.y}</b></td></tr>' +
            '<tr><td style="color: {series.color}">{series.name.tooltipName3}: </td><td style="text-align: right"><b>{point.Y}</b></td></tr>',
          footerFormat: "</table>",
          valueDecimals: 2,
          split: false,
          // xDateFormat: CHART_DATE_FORMATS.dmy
          xDateFormat:
            this.dateFormatObject[this.upm__getFormatDateString]
        },
        data: feedAreaObject,
        type: "area",
        color: "#f7a35c",
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "rgba(247, 163, 92,1)"],
            [1, "rgba(255,255,255,.25)"]
          ]
        },
        visible: true
      });
      this.$nextTick(() => {
        if (!this.$refs.highcharts) return;
        const chart = this.$refs.highcharts.chart;
        const xAxis = chart.xAxis[0];
        xAxis.setExtremes(xAxis.dataMin, xAxis.dataMax);
      });
    },
    async handleValueChange(currentSelectedValues, type = '') {
      this.params.from_date = currentSelectedValues[0];
      this.params.to_date = currentSelectedValues[1];
      await this.initComponent(this.chm__pondId);
      if (type === 'dateChange') {
        this.mixPanelEventGenerator({ eventName: "Ponds - Dashboard - Ponds Cumulative Feed Graph - Date Filter" });
      }
    }
  }
};
</script>
<style scoped lang="scss">
.el-switch__label {
  span {
    color: #0a2463 !important;
  }
}
// .pond-schedule-graph {
//   .el-range-editor {
//     flex-basis: 45%;
//   }
// }
</style>
